import React, { Fragment, useEffect, useState } from "react";
import ProductInfo from "./ProductInfo";
import Tabs from "./Tabs";
import SimilarProducts from "./SimilarProducts";
import RecentylViewd from "./RecentylViewd";
import {
  fetchReviewById,
  getReviewByUser,
  getSimilarProducts,
  fetchProductById,
  fetchProductDetails, // Import your fetch function to get product details by ID
} from "../../../utils";
import { Helmet } from "react-helmet";
import logo from "../../../assets/images/logo.png";
import { useLocation } from "react-router-dom"; // Import useLocation
import { saveToLocalStorage, getFromLocalStorage } from "../../../utils";
const ProductDetailSection = ({ productDetails }) => {
  const [reviews, setReviews] = useState([]);
  const [count, setCount] = useState(0);
  const [userReview, setUserReview] = useState({});
  const [similarProducts, setSimilarProducts] = useState([]);

  const [currentProduct, setCurrentProduct] = useState(productDetails); // New state for current product
  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
  const location = useLocation(); // Get current location

  useEffect(() => {
    const fetchReviews = async () => {
      if (currentProduct._id) {
        const { reviews, count } = await fetchReviewById(currentProduct._id);
        setReviews(reviews);
        setCount(count);
      }
    };
    const fetchSimilar = async () => {
      if (currentProduct._id) {
        const result = await getSimilarProducts(currentProduct._id);
        setSimilarProducts(result.data);
      }
    };
    fetchReviews();
    fetchSimilar();
  }, [currentProduct]); // Use currentProduct as a dependency

  useEffect(() => {
    const fetchUserReview = async () => {
      if (currentProduct._id && isLoggedIn) {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user && user._id) {
          const reviews = await getReviewByUser(currentProduct._id, user._id);
          setUserReview(reviews);
        }
      }
    };
    fetchUserReview();
  }, [currentProduct, isLoggedIn]); // No additional dependencies needed

  // Effect to update product details when URL changes
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const sizeParam = urlParams.get("size");
    if (sizeParam) {
      fetchProductBySize(sizeParam); // Fetch product details based on the selected size
    }
  }, [location.search]); // Add location.search as a dependency
  useEffect(() => {
    let recentlyViewed = getFromLocalStorage("recentlyViewed") || [];

    // Prevent duplicate entries
    recentlyViewed = recentlyViewed.filter(
      (item) => item._id !== productDetails._id
    );

    // Add the new product to the beginning of the list
    recentlyViewed.unshift(productDetails);

    // Limit the list to the last 5 viewed items
    if (recentlyViewed.length > 4) {
      recentlyViewed = recentlyViewed.slice(0, 4);
    }

    // Save to local storage
    saveToLocalStorage("recentlyViewed", recentlyViewed);
  }, [productDetails]);
  const fetchProductBySize = async (size) => {
    const productslug = productDetails.slug; // Assuming product ID is available
    const updatedProduct = await fetchProductDetails(productslug, size);
    setCurrentProduct(updatedProduct); // Update current product state
  };

  const currentUrl = window.location.href;
  const productDes = {
    description: currentProduct ? currentProduct.description : "",
    code: currentProduct ? currentProduct.code : "",
    composition: currentProduct ? currentProduct.composition : "",
    technique: currentProduct ? currentProduct.technique : "",
    design: currentProduct ? currentProduct.category.name : "",
    collection: currentProduct ? currentProduct.collection.name : "",
  };

  return (
    <Fragment>
      <Helmet>
        <title>{currentProduct ? currentProduct.name : "Rug"}</title>
        <meta
          property="og:title"
          content={currentProduct ? currentProduct.name : "Rug"}
        />
        <meta
          property="og:description"
          content={
            currentProduct ? currentProduct.description : "Rug Description"
          }
        />
        <meta
          property="og:image"
          content={currentProduct ? currentProduct.images[0].url : logo}
        />
        <meta property="og:url" content={currentUrl} />
        {/* <meta property="og:type" content="website" /> */}
      </Helmet>
      <ProductInfo productDetails={currentProduct} />

      <Tabs
        reviews={reviews}
        count={count}
        userReview={userReview}
        pid={currentProduct ? currentProduct._id : ""}
        product={productDes}
      />
      {similarProducts.length !== 0 && (
        <SimilarProducts similarProducts={similarProducts} />
      )}
      <RecentylViewd />
    </Fragment>
  );
};

export default ProductDetailSection;
