import React, { Fragment, useEffect, useState } from "react";
import prod1 from "../../../assets/images/product-1 (2).png";
import prod2 from "../../../assets/images/product-1 (1).png";
import { formatPrice, getFromLocalStorage } from "../../../utils";
const RecentylViewd = () => {
  const [recentlyViewedItems, setRecentlyViewedItems] = useState([]);
  useEffect(() => {
    const items = getFromLocalStorage("recentlyViewed");
    setRecentlyViewedItems(items);
  }, []);
  const handleViewDetails = (e, slug, size) => {
    e.stopPropagation();
    window.location.href = `/product/${slug}?size=${size}`;
  };
  return (
    <Fragment>
      <div className="product-dec-2">
        <h2>Recently Viewed</h2>
        <div className="recently-view-dec">
          {recentlyViewedItems.map((item) => (
            <div
              className="recently-item"
              key={item._id}
              onClick={(e) =>
                handleViewDetails(e, item.slug, item.sizes[0].slug)
              }
            >
              <div className="item-image">
                <img src={item.images[0].url} alt="" />
                {/* <div className="overlay">
     <a href="">Buy Now</a>
   </div> */}
              </div>
              <div className="recently-item-content">
                <h5>
                  {item.name
                    .toLowerCase()
                    .replace(/\b\w/g, (char) => char.toUpperCase())}
                </h5>
                {item.priceSale ? (
                  <p className="price-item">
                    <span className="original-price">
                      {formatPrice(item.price)}
                    </span>{" "}
                    <span className="sale-price">
                      {formatPrice(item.priceSale)}
                    </span>
                  </p>
                ) : (
                  <p className="price">{formatPrice(item.price)}</p>
                )}
              </div>
              <button
                className="buy-now-btn"
                onClick={(e) =>
                  handleViewDetails(e, item.slug, item.sizes[0].slug)
                }
              >
                View Details
              </button>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default RecentylViewd;
