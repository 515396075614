export const loadGTM = (gtmId) => {
  if (!gtmId) return;

  // Check if GTM is already added
  if (document.getElementById("google-tag-manager")) return;

  // Create GTM script tag
  const script = document.createElement("script");
  script.id = "google-tag-manager";
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;

  // Append to head
  document.head.appendChild(script);

  // Create GTM noscript fallback
  const noscript = document.createElement("noscript");
  noscript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
  document.body.appendChild(noscript);
};
