import React, { Fragment, useState, useEffect } from "react";
import "../../../assets/css/Abstract.scss";
import FilterList from "./FilterList";
import Products from "./Products";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
const MainProduct = ({
  sizes,
  colors,
  rooms,
  patterns,
  shapes,
  collections,
  categories,
  filterParams,
  updateFilters,
  filteredProducts,
  count,
  filterValues,
  range,
  handleLoadMore,
  loading,
}) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [showViewCart, setShowViewCart] = useState(false);
  const navigate = useNavigate();
  const currentUrl = window.location.href;

  useEffect(() => {
    const item = localStorage.getItem("selectedItem");
    if (item) {
      setSelectedItem(JSON.parse(item));
    }
  }, []);
  const handleViewCart = () => {
    setShowViewCart(true);
    window.scrollTo({ top: 0, behavior: "smooth" });

    // Hide "View Cart" after a few seconds
    setTimeout(() => {
      setShowViewCart(false);
    }, 6000);
  };
  const handleClickViewCart = () => {
    window.location.href = "/cart";
  };

  return (
    <Fragment>
      {console.log(
        selectedItem
          ? `${selectedItem.name} | Rugs | D'Decor Rugs`
          : "All Rugs | D'Decor"
      )}
      <Helmet>
        <title>
          {selectedItem
            ? `${selectedItem.name} | Rugs | D'Decor Rugs`
            : "All Rugs | D'Decor"}
        </title>

        <meta
          property="og:title"
          content={
            selectedItem
              ? `${selectedItem.name} | Rugs | D'Decor Rugs`
              : "All Rugs | D'Decor"
          }
        />
        {/* <meta
          name="description"
          content={
            selectedItem ? selectedItem.metaDescription : "defaultDescription"
          }
        /> */}
        {/* <meta
          property="og:description"
          content={
            selectedItem ? selectedItem.metaDescription : "defaultDescription"
          }
        /> */}
        <meta property="og:url" content={currentUrl} />
        {/* <meta property="og:type" content="website" /> */}
      </Helmet>
      {showViewCart && (
        <div className="viewCartBoxContainer">
          <div className="addToCartBox">
            <p className="boxText">Product has been added to cart.</p>
            <button className="viewCartBox" onClick={handleClickViewCart}>
              {/* View Cart */}
              VIEW CART
            </button>
          </div>
        </div>
      )}
      <div className="abstract-page-container">
        <div className="abstract-main-content">
          <FilterList
            sizes={sizes}
            colors={colors}
            rooms={rooms}
            shapes={shapes}
            patterns={patterns}
            collections={collections}
            categories={categories}
            filterParams={filterParams}
            updateFilters={updateFilters}
            range={range}
          />
          <Products
            sizes={sizes}
            colors={colors}
            rooms={rooms}
            shapes={shapes}
            patterns={patterns}
            collections={collections}
            categories={categories}
            filterParams={filterParams}
            updateFilters={updateFilters}
            products={filteredProducts}
            count={count}
            filterValues={filterValues}
            showViewCart={showViewCart}
            handleViewCart={handleViewCart}
            handleLoadMore={handleLoadMore}
            loading={loading}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default MainProduct;
