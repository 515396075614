import React, { Fragment, useState } from "react";
import prod1 from "../../../assets/images/product-1 (1).png";
import prod2 from "../../../assets/images/product-1 (2).png";
import prod3 from "../../../assets/images/product-1 (3).png";
import prod4 from "../../../assets/images/product-1 (4).png";
import CustomArrowLeft from "../../Common/CustomLeftArrow";
import CustomArrowRight from "../../Common/CustomRightArrow";
import { formatPrice } from "../../../utils";
const SimilarProducts = ({ similarProducts }) => {
  const [startIndex, setStartIndex] = useState(0);
  const handleNext = () => {
    if (startIndex + 4 < similarProducts.length) {
      setStartIndex(startIndex + 1);
    }
  };
  const handlePrev = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1); // Slide one item back
    }
  };
  const handleViewDetails = (e, slug, size) => {
    e.stopPropagation();
    window.location.href = `/product/${slug}?size=${size}`;
  };
  return (
    <Fragment>
      <div className="product-dec">
        <h2>Similar products</h2>
        <div className="similarProduct-carousel">
          {/* <button
            className="prev-similar-btn"
            onClick={handlePrev}
            disabled={startIndex === 0}
          >
            <CustomArrowLeft />
          </button> */}
          <div className="similar-product-wrapper">
            <div
              className="product-row"
              style={{
                transform: `translateX(-${startIndex * 25}%)`, // Adjust sliding by 25% for each item
              }}
            >
              {similarProducts.map((prod) => (
                <div
                  className="product-item"
                  key={prod._id}
                  onClick={(e) =>
                    handleViewDetails(e, prod.slug, prod.sizes[0].size)
                  }
                >
                  <div className="similar-product-image">
                    <img src={prod.images[0].url} alt="Product 1" />
                  </div>
                  <div className="heading-price">
                    <h3>
                      {prod.name
                        .toLowerCase()
                        .replace(/\b\w/g, (char) => char.toUpperCase())}
                    </h3>
                    {prod.priceSale ? (
                      <p className="price-item">
                        <span className="original-price">
                          {formatPrice(prod.price)}
                        </span>{" "}
                        <span className="sale-price">
                          {formatPrice(prod.priceSale)}
                        </span>
                      </p>
                    ) : (
                      <p className="price">{formatPrice(prod.price)}</p>
                    )}
                  </div>
                  <button
                    className="buy-now-btn"
                    onClick={(e) =>
                      handleViewDetails(e, prod.slug, prod.sizes[0].size)
                    }
                  >
                    View Details
                  </button>
                </div>
              ))}
            </div>
          </div>
          {/* <button
            className="next-similar-btn"
            onClick={handleNext}
            disabled={startIndex + 4 >= similarProducts.length}
          >
            <CustomArrowRight />
          </button> */}
        </div>
      </div>
    </Fragment>
  );
};

export default SimilarProducts;
