import { useEffect, useState, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "../../../assets/css/ProfileEdit.scss";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { FaEye, FaEyeSlash } from "react-icons/fa";
const indianStates = [
  "Andaman and Nicobar Islands",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chandigarh",
  "Chhattisgarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Lakshadweep",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Puducherry",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
];
const ProfileEdit = ({ onCancel, onSave, userData }) => {
  const [isStateOpen, setIStateOpen] = useState(false);
  const [isCountryOpen, setIsCountryOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const stateRef = useRef(null);
  const countryRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [valuePhone, setValuePhone] = useState("");
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        stateRef.current &&
        !stateRef.current.contains(event.target) &&
        countryRef.current &&
        !countryRef.current.contains(event.target)
      ) {
        setIStateOpen(false);
        setIsCountryOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleOptionSelect = (setFieldValue, fieldName, value) => {
    setFieldValue(fieldName, value);
    if (fieldName === "state") setIStateOpen(false);
    if (fieldName === "country") setIsCountryOpen(false);
  };
  const validationSchema = Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    phone: Yup.string()
      .matches(/^[0-9]+$/, "Mobile Number should be numeric")
      .min(10, "Mobile Number must be at least 10 digits")
      .required("Mobile Number is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    gender: Yup.string()
      .required("Gender is required")
      .test(
        "is-valid-gender",
        "Gender must be male, female, or other",
        (value) => {
          if (!value) return false; // Handle required validation
          const lowerCaseValue = value.toLowerCase();
          return ["male", "female", "other"].includes(lowerCaseValue);
        }
      ),
    // currentPassword: Yup.string().required("Current Password is required"),
    new_password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Must contain one lowercase, one uppercase, one number, and one special character"
      ),

    confirmPassword: Yup.string().oneOf(
      [Yup.ref("new_password"), null],
      "Passwords must match"
    ),

    address: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
    pincode: Yup.string()
      .matches(/^[0-9]{5,6}$/, "Invalid Pincode")
      .required("Pincode is required"),
  });
  const capitalize = (text) => text.charAt(0).toUpperCase() + text.slice(1);

  const initialValues = {
    firstName: userData?.firstName || "",
    lastName: userData?.lastName || "",
    phone: userData?.phone || "",
    email: userData?.email || "",
    gender: userData?.gender ? capitalize(userData.gender) : "",
    old_password: "",
    new_password: "",
    confirmPassword: "",
    address: userData?.address || "",
    city: userData?.city || "",
    state: userData?.state || "",
    country: userData?.country || "India",
    pincode: userData?.zip || "",
  };

  const handleSubmit = (values) => {
    onSave(values); // Pass the form values to the onSave function
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };
  return (
    <div className="profile-edit-page-container">
      <div className="profile-edit-main-content">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values }) => (
            <Form>
              {/* Form fields */}
              <div className="edit-two-input-box">
                <div className="label-and-input-box-edit profile-edit-field">
                  <Field
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                  />
                  <ErrorMessage
                    name="firstName"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="label-and-input-box-edit profile-edit-field">
                  <Field type="text" name="lastName" placeholder="Last Name" />
                  <ErrorMessage
                    name="lastName"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
              <div className="edit-two-input-box">
                <div className="label-and-input-box-edit profile-edit-field">
                  {isFocused || values.phone ? (
                    <span className="default-india-code-profile">+91</span>
                  ) : null}
                  <Field
                    type="text"
                    name="phone"
                    className="three-input"
                    placeholder="Phone Number"
                    style={{
                      paddingLeft: isFocused || values.phone ? "51px" : "20px", // Adjust 12px to your normal padding
                    }}
                    // value={valuePhone}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    // onChange={(e) => setValuePhone(e.target.value)}
                  />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="label-and-input-box-edit profile-edit-field">
                  <Field
                    type="text"
                    name="email"
                    className="three-input"
                    placeholder="Email Id"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="label-and-input-box-edit profile-edit-field">
                  <Field
                    type="text"
                    name="gender"
                    className="three-input"
                    placeholder="Gender"
                  />
                  <ErrorMessage
                    name="gender"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
              <div className="edit-two-input-box">
                <div className="label-and-input-box-edit profile-edit-field">
                  <Field
                    type={showPassword ? "text" : "password"}
                    name="old_password"
                    className="three-input"
                    placeholder="Current Password"
                  />
                  <span
                    className="password-edit-icon"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                  <ErrorMessage
                    name="old_password"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="label-and-input-box-edit profile-edit-field">
                  <Field
                    type={showNewPassword ? "text" : "password"}
                    name="new_password"
                    className="three-input"
                    placeholder="New Password"
                  />
                  <span
                    className="newpassword-edit-icon"
                    onClick={toggleNewPasswordVisibility}
                  >
                    {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                  <ErrorMessage
                    name="new_password"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="label-and-input-box-edit profile-edit-field">
                  <Field
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    className="three-input"
                    placeholder="Confirm Password"
                  />
                  <span
                    className="confirmpassword-edit-icon"
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                  <ErrorMessage
                    name="confirmPassword"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
              <div className="edit-two-input-box">
                <div className="label-and-input-box-edit Address-input profile-edit-field">
                  <Field type="text" name="address" placeholder="Address" />
                  <ErrorMessage
                    name="address"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="label-and-input-box-edit city-input profile-edit-field">
                  <Field type="text" name="city" placeholder="City" />
                  <ErrorMessage name="city" component="div" className="error" />
                </div>
              </div>
              <div className="edit-two-input-box profile-edit-field">
                {/* <div className="form-field-state-field-edit">
                  <div
                    className="form-field-input-state state-field"
                    ref={stateRef}
                  >
                    <div
                      className="dropdown"
                      onClick={() => setIStateOpen(!isStateOpen)}
                    >
                      <Field
                        type="text"
                        name="state"
                        placeholder="State"
                        className="input-with-icons"
                        readOnly
                      />
                      {isStateOpen ? (
                        <MdKeyboardArrowUp size={24} />
                      ) : (
                        <MdKeyboardArrowDown size={24} />
                      )}
                    </div>
                    {isStateOpen && (
                      <div className="dropdown-options">
                        {indianStates.map((option, index) => (
                          <div
                            key={index}
                            onClick={() =>
                              handleOptionSelect(setFieldValue, "state", option)
                            }
                            className={`dropdown-option ${
                              values.state === option ? "selected" : ""
                            }`} // Add 'selected' class for highlighting
                          >
                            {option}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <ErrorMessage
                    name="state"
                    component="div"
                    className="error"
                  />
                </div> */}
                <div className="form-field-state-field">
                  <div
                    className="form-field-input-state state-field"
                    ref={stateRef}
                  >
                    <div className="dropdown">
                      <Field
                        type="text"
                        name="state"
                        placeholder="State"
                        className="input-with-icons"
                        value={values.state}
                        onClick={() => setIStateOpen(true)} // Open dropdown on click
                        onChange={(e) => {
                          setFieldValue("state", e.target.value); // Update Formik state
                          setIStateOpen(true); // Ensure dropdown stays open
                        }}
                      />
                      {isStateOpen ? (
                        <MdKeyboardArrowUp
                          size={24}
                          onClick={() => setIStateOpen(false)} // Close dropdown
                        />
                      ) : (
                        <MdKeyboardArrowDown
                          size={24}
                          onClick={() => setIStateOpen(true)} // Open dropdown
                        />
                      )}
                    </div>

                    {isStateOpen && (
                      <div
                        className="dropdown-options"
                        style={{ maxHeight: "150px", overflowY: "auto" }}
                      >
                        {[
                          // Matches first
                          ...indianStates.filter((option) =>
                            option
                              .toLowerCase()
                              .startsWith(values.state.toLowerCase())
                          ),
                          // Non-matches next
                          ...indianStates.filter(
                            (option) =>
                              !option
                                .toLowerCase()
                                .startsWith(values.state.toLowerCase())
                          ),
                        ].map((option, index) => (
                          <div
                            key={index}
                            onClick={() =>
                              handleOptionSelect(setFieldValue, "state", option)
                            }
                            className={`dropdown-option ${
                              values.state.toLowerCase() ===
                              option.toLowerCase()
                                ? "selected"
                                : ""
                            }`}
                          >
                            {option}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <ErrorMessage
                    name="state"
                    component="div"
                    className="error"
                  />
                </div>
                {/* Country Dropdown */}
                <div
                  className="form-field-input country-field state-field"
                  ref={countryRef}
                >
                  <div
                    className="dropdown"
                    onClick={() => setIsCountryOpen(!isCountryOpen)}
                  >
                    <Field
                      type="text"
                      name="country"
                      placeholder="Country"
                      className="input-with-icons"
                      readOnly
                    />
                    {isCountryOpen ? (
                      <MdKeyboardArrowUp size={24} />
                    ) : (
                      <MdKeyboardArrowDown size={24} />
                    )}
                  </div>
                  {isCountryOpen && (
                    <div className="dropdown-options">
                      <div
                        onClick={() =>
                          handleOptionSelect(setFieldValue, "country", "India")
                        }
                        className={`dropdown-option ${
                          values.country === "India" ? "selected" : ""
                        }`}
                      >
                        India
                      </div>
                    </div>
                  )}
                </div>
                <div className="label-and-input-box-edit">
                  <Field
                    type="text"
                    name="pincode"
                    className="three-input"
                    placeholder="Pincod"
                  />
                  <ErrorMessage
                    name="pincode"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
              {/* Action buttons */}
              <div className="profile-edit-buttons">
                <button
                  type="button"
                  className="cancel-button"
                  onClick={onCancel}
                >
                  CANCEL
                </button>
                <button type="submit" className="save-button">
                  SAVE
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ProfileEdit;
