import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const RouteChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "page_view",
      page_location: location.pathname + location.search,
      pageTitle: document.title,
    });
    console.log(location.pathname + location.search);
  }, [location]);

  return null;
};

export default RouteChangeTracker;
