import React, { useState, useEffect, useRef } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import "../../assets/css/ContactUs.scss";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { baseURL } from "../../utils";
import { Snackbar, Alert } from "@mui/material";
import axios from "axios";
import img6 from "../../assets/images/img-6.jpg";
import img9 from "../../assets/images/img-9.jpg";
import Reactangle2 from "../../assets/images/Rectangle 6720.png";
import contactus from "../../assets/images/contact-us.png";
import { Helmet } from "react-helmet";
// Validation schema using Yup
const validationSchema = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
});
const ContactUs = () => {
  const [isBusinessOpen, setIsBusinessOpen] = useState(false);
  const [isSubjectOpen, setIsSubjectOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const businessRef = useRef(null);
  const subjectRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [valuePhone, setValuePhone] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top on page load

    const handleClickOutside = (event) => {
      if (
        businessRef.current &&
        !businessRef.current.contains(event.target) &&
        subjectRef.current &&
        !subjectRef.current.contains(event.target)
      ) {
        setIsBusinessOpen(false);
        setIsSubjectOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleOptionSelect = (setFieldValue, fieldName, value) => {
    setFieldValue(fieldName, value);
    if (fieldName === "business") setIsBusinessOpen(false);
    if (fieldName === "subject") setIsSubjectOpen(false);
  };
  const handleRedirect = (item) => {
    window.location.href = item;
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const businessOptions = [
    "Customer",
    "Trade",
    "Architect",
    "Interior Design",
    "Media",
    "Others",
  ];
  const subjectOptions = [
    "Stock Availability",
    "Trade Enquiry",
    "Franchise Enquiry",
    "Project Enquiry",
    "Media Enquiry",
    "Others",
  ];
  const handleSubmit = async (values, { resetForm }) => {
    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      business: values.business,
      subject: values.subject,
      city: values.city,
      message: values.message,
    };

    // Call your API here
    try {
      const response = await axios.post(`${baseURL}/contactUs`, payload);
      if (response.data.success === true) {
        setSnackbarMessage(
          "Thank you for contacting us! Our team will connect with you as soon as possible "
        );
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        resetForm();
      } else {
        setSnackbarMessage(response.data.message);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("Something went wrong!!!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };
  return (
    <>
      <Helmet>
        <title>Contact Us | D'decor Rugs</title>
        <meta property="og:title" content="Contact Us | D'decor Rugs" />
      </Helmet>
      <Header />
      <div className="contactus-page-container">
        <div className="header-of-login">
          <p>
            <span
              onClick={() => handleRedirect("/")}
              style={{ cursor: "pointer" }}
            >
              Home
            </span>{" "}
            - Contact Us
          </p>
        </div>
        <div className="header-of-login-mobile-view">
          <p>
            <span
              onClick={() => handleRedirect("/")}
              style={{ cursor: "pointer" }}
            >
              Home
            </span>{" "}
            - Contact Us
          </p>
        </div>
        <div className="contactus-main-content">
          <h2 className="contact-us-h2">CONTACT US</h2>
          <div className="contact-us-form-details-and-heading">
            <h5>Get In Touch With Us</h5>
            <div className="contact-us-main-form">
              <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                  email: "",
                  phone: "",
                  business: "",
                  subject: "",
                  city: "",
                  message: "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ setFieldValue, values }) => (
                  <Form className="contact-us-form-main-content">
                    <div className="contact-us-inputs-form">
                      <div className="form-field">
                        <Field
                          type="text"
                          name="firstName"
                          placeholder="First Name"
                        />
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className="error"
                        />
                      </div>
                      <div className="form-field">
                        <Field
                          type="text"
                          name="lastName"
                          placeholder="Last Name"
                        />
                        <ErrorMessage
                          name="lastName"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>
                    <div className="contact-us-inputs-form">
                      <div className="form-field">
                        <Field type="text" name="email" placeholder="Email" />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error"
                        />
                      </div>
                      {/* <div className="form-field">
                        <span className="default-india-code-main">+91</span>
                        <Field type="text" name="phone" placeholder="Phone" />
                      </div> */}
                      <div className="form-field">
                        {isFocused || valuePhone ? (
                          <span className="default-india-code-main-field">
                            +91
                          </span>
                        ) : null}
                        <Field
                          type="text"
                          name="phone"
                          placeholder="Phone Number *"
                          style={{
                            paddingLeft:
                              isFocused || valuePhone ? "51px" : "20px", // Adjust 12px to your normal padding
                          }}
                          value={valuePhone}
                          onFocus={() => setIsFocused(true)}
                          onBlur={() => setIsFocused(false)}
                          onChange={(e) => setValuePhone(e.target.value)}
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>
                    <div className="contact-us-inputs-form">
                      <div
                        className="form-field-input business-filed"
                        ref={businessRef}
                      >
                        <div
                          className="dropdown"
                          onClick={() => setIsBusinessOpen(!isBusinessOpen)}
                        >
                          <Field
                            type="text"
                            name="business"
                            placeholder="Business"
                            className="input-with-icons"
                            value={values.business || "Select Business"}
                            readOnly
                          />
                          {isBusinessOpen ? (
                            <MdKeyboardArrowUp size={24} />
                          ) : (
                            <MdKeyboardArrowDown size={24} />
                          )}
                        </div>
                        {isBusinessOpen && (
                          <div className="dropdown-options">
                            {businessOptions.map((option, index) => (
                              <div
                                key={index}
                                onClick={() =>
                                  handleOptionSelect(
                                    setFieldValue,
                                    "business",
                                    option
                                  )
                                }
                                className={`dropdown-option ${
                                  values.business === option ? "selected" : ""
                                }`}
                              >
                                {option}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <div
                        className="form-field-input  subject-input-filed"
                        ref={subjectRef}
                      >
                        <div
                          className="dropdown"
                          onClick={() => setIsSubjectOpen(!isSubjectOpen)}
                        >
                          <Field
                            type="text"
                            name="subject"
                            placeholder="Subject"
                            className="input-with-icons"
                            value={values.subject || "Select Subject"}
                            readOnly
                          />
                          {isSubjectOpen ? (
                            <MdKeyboardArrowUp size={24} />
                          ) : (
                            <MdKeyboardArrowDown size={24} />
                          )}
                        </div>
                        {isSubjectOpen && (
                          <div className="dropdown-options for-mobile">
                            {subjectOptions.map((option, index) => (
                              <div
                                key={index}
                                onClick={() =>
                                  handleOptionSelect(
                                    setFieldValue,
                                    "subject",
                                    option
                                  )
                                }
                                className={`dropdown-option ${
                                  values.subject === option ? "selected" : ""
                                }`}
                              >
                                {option}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="contact-us-inputs-form-city-field">
                      <div className="form-field">
                        <Field type="text" name="city" placeholder="City" />
                      </div>
                    </div>
                    <div className="contact-us-inputs-form">
                      <div className="form-field">
                        <Field
                          as="textarea"
                          name="message"
                          rows="5"
                          placeholder="Message"
                        />
                      </div>
                    </div>
                    <button type="submit" className="contact-us-send-button">
                      SEND
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <div className="contactus-address-details-and-image">
          <div className="contactus-address-image">
            <div className="my-contactus-image-div">
              <img src={img6} />
            </div>
          </div>
          <div className="contactus-address-email-phone-number">
            <div className="all-email-phone-details-of-contact">
              <h5>CONTACT US</h5>
              <div className="headquarters-details">
                <h2>Headquarters</h2>
                <p>
                  D'Decor Curtain Fabrics LLP S14, Solitaire Corporate Park, 5th
                  Floor, 167, Guru Hargovindji Marg, Chakala, Andheri (E) Mumbai
                  – 400093, India
                </p>
              </div>
              <p className="headquarters-text">
                Email:
                <p className="email-text-head">support@ddecorrugs.com</p>
              </p>
              {/* <p className="headquarters-text">
                Phone:
                <p className="email-text-head">+91 (22) 6115 2525</p>
              </p> */}
              <p className="headquarters-text">
                Mobile:
                <p className="email-text-head">7718801634</p>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%", textAlign: "center" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ContactUs;
