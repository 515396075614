import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
const Checkout = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [address, setAddress] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch login status and address from localStorage
    const loggedInStatus = localStorage.getItem("isLoggedIn") === "true";
    const storedAddress = JSON.parse(localStorage.getItem("address") || "{}");
    const storedUser = JSON.parse(localStorage.getItem("user") || "{}");
    localStorage.setItem("dataComingFrom", "buy-now");
    setIsLoggedIn(loggedInStatus);
    setAddress(storedAddress);
    const isAddressEmpty = (address) => {
      return !address || Object.keys(address).length === 0;
    };
    const isPhoneMissing = (user) =>
      !user || !user.phone || user.phone.trim() === "";
    // Redirect logic
    if (loggedInStatus) {
      if (!isAddressEmpty(storedAddress) && !isPhoneMissing(storedUser)) {
        navigate("/payment"); // Proceed to payment
      } else {
        navigate("/checkoutAddress"); // Redirect to address page if address or phone is missing
      }
    } else {
      navigate("/checkoutLogin"); // Redirect to login if not logged in
    }
  }, [navigate]);

  return (
    <Fragment>
      <Helmet>
        <title>Checkout | D'decor Rugs</title>
        <meta property="og:title" content="Checkout | D'decor Rugs" />
      </Helmet>
    </Fragment>
  ); // This component is used only for redirection
};

export default Checkout;
