import React, { Fragment, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "../../assets/css/ReturnOrder.scss";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { Helmet } from "react-helmet";
import Loader from "../Common/Loader";
import axios from "axios";
import { baseURL } from "../../utils";
import { Snackbar, Alert } from "@mui/material";
import { useSearchParams, useNavigate } from "react-router-dom";
const ReturnOrder = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [searchParams] = useSearchParams(); // Access URL parameters
  const orderNoFromURL = searchParams.get("orderNo"); // Extract orderNo from URL
  const handleRedirect = (item) => {
    window.location.href = item;
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false); // Close Snackbar
  };
  const validationSchema = Yup.object({
    orderNo: Yup.string().required("Order No is required"),
    reasons: Yup.string().required("Reason is required"),
  });

  const initialValues = {
    orderNo: orderNoFromURL || "", // Prefill the input with the orderNo from the URL
    reasons: "",
  };
  const handleSubmit = async (values) => {
    const body = {
      orderId: values.orderNo,
      reason: values.reasons,
    };
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const response = await axios.post(`${baseURL}/return-order`, body, {
        headers: {
          Authorization: `Bearer ${token}`, // Include token in Authorization header
        },
      });
      if (response.data.status === true) {
        setSnackbarMessage(
          "Your return request has been submitted successfully!"
        );
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setTimeout(() => navigate("/my-account"), 2000); // Redirect after 2 seconds
      } else {
        setSnackbarMessage(
          response.data.message ||
            "Something Went Wrong! Please try again later"
        );
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("Something Went Wrong! Please try again later");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Fragment>
      <Helmet>
        <title>Return Your Order | D'decor Rugs</title>
        <meta property="og:title" content="Return Your Order | D'decor Rugs" />
      </Helmet>
      <Header />
      <div className="intro-return-container">
        <div className="heading-return">
          <h2>
            <span
              onClick={() => handleRedirect("/")}
              style={{ cursor: "pointer" }}
            >
              Home
            </span>{" "}
            -{" "}
            <span
              onClick={() => handleRedirect("/rugs")}
              style={{ cursor: "pointer" }}
            >
              Shop -{" "}
            </span>
            <span>Return my Order</span>
          </h2>
        </div>
      </div>
      <div className="return-order-container">
        <h1>Return Your Order</h1>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form className="return-order-form">
              <div className="form-group">
                <label htmlFor="order-id">Order No</label>
                <Field
                  type="text"
                  id="order-id"
                  name="orderNo"
                  placeholder="Found in your order confirmation email."
                />
                <ErrorMessage
                  name="orderNo"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="form-group">
                <label htmlFor="reasons">Reason</label>
                <Field
                  as="textarea"
                  id="reasons"
                  name="reasons"
                  placeholder="Message"
                />
                <ErrorMessage
                  name="reasons"
                  component="div"
                  className="error-message"
                />
              </div>
              <button type="submit">{loading ? <Loader /> : "RETURN"}</button>
            </Form>
          )}
        </Formik>
      </div>

      <Footer />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // Centering the Snackbar
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%", textAlign: "center" }} // Centering the text inside the Alert
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default ReturnOrder;
