import React, { useEffect, useState, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import "../../assets/css/Address.scss";
import cartone from "../../assets/images/cart1.png";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { updateUser } from "../../utils";
import CheckoutOrderSummary from "../Common/CheckoutOrderSummary";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../../store/cart";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import Loader from "../Common/Loader";
import { Helmet } from "react-helmet";
const indianStates = [
  "Andaman and Nicobar Islands",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chandigarh",
  "Chhattisgarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Lakshadweep",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Puducherry",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
];

const CheckoutAddressCart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.cart);
  const [user, setUser] = useState({});
  const [email, setEmail] = useState("");
  const [addressData, setAddress] = useState({});
  const [isStateOpen, setIStateOpen] = useState(false);
  const [isCountryOpen, setIsCountryOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const stateRef = useRef(null);
  const countryRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [valuePhone, setValuePhone] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top on page load
    const handleClickOutside = (event) => {
      if (
        stateRef.current &&
        !stateRef.current.contains(event.target) &&
        countryRef.current &&
        !countryRef.current.contains(event.target)
      ) {
        setIStateOpen(false);
        setIsCountryOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleOptionSelect = (setFieldValue, fieldName, value) => {
    setFieldValue(fieldName, value);
    if (fieldName === "state") setIStateOpen(false);
    if (fieldName === "country") setIsCountryOpen(false);
  };

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    const storedAdd = localStorage.getItem("address");
    if (storedUser) setUser(JSON.parse(storedUser));
    if (storedAdd) setAddress(JSON.parse(storedAdd));

    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUser(parsedUser);

      // Extract email from user object
      if (parsedUser.email) {
        setEmail(parsedUser.email);
      } else {
        setEmail("");
      }
    }
  }, []);

  useEffect(() => {
    const savedCart = JSON.parse(localStorage.getItem("cart")) || [];
    if (savedCart.length > 0) {
      dispatch(cartActions.setCart(savedCart));
    }
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cartItems));
  }, [cartItems]);

  const validationSchema = Yup.object({
    address: Yup.string().required("Shipping Address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
    zip: Yup.string()
      .matches(/^[0-9]{6}$/, "Pincode must be exactly 6 digits")
      .required("Pincode is required"),
    phone: Yup.string()
      .required("Phone Number is required")
      .matches(/^\d{10}$/, "Phone number must be exactly 10 digits"),
  });

  const handleSaveAddress = async (values) => {
    const token = localStorage.getItem("token");

    try {
      setLoading(true);
      const response = await updateUser(values, token);
      const address = {
        address: values.address,
        city: values.city,
        state: values.state,
        country: values.country,
        zip: values.zip,
      };
      localStorage.setItem("user", JSON.stringify(response.data));
      localStorage.setItem("address", JSON.stringify(address));
      localStorage.setItem("dataComingFrom", "add-to-cart");
      navigate("/payment");
    } catch (error) {
      console.error("Error updating user:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleRedirect = (item) => {
    window.location.href = item;
  };

  return (
    <>
      <Helmet>
        <title>Checkout | D'decor Rugs</title>
        <meta property="og:title" content="Checkout | D'decor Rugs" />
      </Helmet>
      <Header />
      <div className="address-page-container">
        <div className="header-of-login">
          <p>
            <span
              onClick={() => handleRedirect("/")}
              style={{ cursor: "pointer" }}
            >
              Home
            </span>{" "}
            - Checkout
          </p>
        </div>
        <div className="header-of-login-mobile-view">
          <p>
            <span
              onClick={() => handleRedirect("/")}
              style={{ cursor: "pointer" }}
            >
              Home
            </span>{" "}
            - Checkout
          </p>
        </div>
        <div className="checkout-address-content">
          <h5>SHIPPING ADDRESS</h5>
          <div className="checkout-address-form-left-right">
            <div className="checkout-address-form-left">
              <Formik
                enableReinitialize
                initialValues={{
                  firstName: user.firstName || "",
                  lastName: user.lastName || "",
                  address: addressData.address || "",
                  city: addressData.city || "",
                  state: addressData.state || "",
                  country: addressData.country || "India",
                  zip: addressData.zip || "",
                  phone: user.phone || "",
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => handleSaveAddress(values)}
              >
                {({ setFieldValue, values }) => (
                  <Form className="address-form-inputs">
                    <div className="two-input-box">
                      <div className="label-and-input-box">
                        <Field
                          type="text"
                          name="firstName"
                          placeholder="First Name"
                        />
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className="error"
                        />
                      </div>
                      <div className="label-and-input-box">
                        <Field
                          type="text"
                          name="lastName"
                          placeholder="Last Name"
                        />
                        <ErrorMessage
                          name="lastName"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>
                    <div className="label-and-input-box">
                      <Field
                        type="text"
                        name="address"
                        placeholder="Shipping Address"
                      />
                      <ErrorMessage
                        name="address"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="two-input-box">
                      <div className="label-and-input-box">
                        <Field
                          type="text"
                          name="city"
                          className="three-input"
                          placeholder="City"
                        />
                        <ErrorMessage
                          name="city"
                          component="div"
                          className="error"
                        />
                      </div>
                      {/* <div className="form-field-state-field">
                        <div
                          className="form-field-input-state state-field"
                          ref={stateRef}
                        >
                          <div
                            className="dropdown"
                            onClick={() => setIStateOpen(!isStateOpen)}
                          >
                            <Field
                              type="text"
                              name="state"
                              placeholder="State"
                              className="input-with-icons"
                              readOnly
                            />
                            {isStateOpen ? (
                              <MdKeyboardArrowUp size={24} />
                            ) : (
                              <MdKeyboardArrowDown size={24} />
                            )}
                          </div>

                          {isStateOpen && (
                            <div className="dropdown-options">
                              {indianStates.map((option, index) => (
                                <div
                                  key={index}
                                  onClick={() =>
                                    handleOptionSelect(
                                      setFieldValue,
                                      "state",
                                      option
                                    )
                                  }
                                  className={`dropdown-option ${
                                    values.state === option ? "selected" : ""
                                  }`}
                                >
                                  {option}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                        <ErrorMessage
                          name="state"
                          component="div"
                          className="error"
                        />
                      </div> */}
                      <div className="form-field-state-field">
                        <div
                          className="form-field-input-state state-field"
                          ref={stateRef}
                        >
                          <div className="dropdown">
                            <Field
                              type="text"
                              name="state"
                              placeholder="State"
                              className="input-with-icons"
                              value={values.state}
                              onClick={() => setIStateOpen(true)} // Open dropdown on click
                              onChange={(e) => {
                                setFieldValue("state", e.target.value); // Update Formik state
                                setIStateOpen(true); // Ensure dropdown stays open
                              }}
                            />
                            {isStateOpen ? (
                              <MdKeyboardArrowUp
                                size={24}
                                onClick={() => setIStateOpen(false)} // Close dropdown
                              />
                            ) : (
                              <MdKeyboardArrowDown
                                size={24}
                                onClick={() => setIStateOpen(true)} // Open dropdown
                              />
                            )}
                          </div>

                          {isStateOpen && (
                            <div
                              className="dropdown-options"
                              style={{ maxHeight: "150px", overflowY: "auto" }}
                            >
                              {[
                                // Matches first
                                ...indianStates.filter((option) =>
                                  option
                                    .toLowerCase()
                                    .startsWith(values.state.toLowerCase())
                                ),
                                // Non-matches next
                                ...indianStates.filter(
                                  (option) =>
                                    !option
                                      .toLowerCase()
                                      .startsWith(values.state.toLowerCase())
                                ),
                              ].map((option, index) => (
                                <div
                                  key={index}
                                  onClick={() =>
                                    handleOptionSelect(
                                      setFieldValue,
                                      "state",
                                      option
                                    )
                                  }
                                  className={`dropdown-option ${
                                    values.state.toLowerCase() ===
                                    option.toLowerCase()
                                      ? "selected"
                                      : ""
                                  }`}
                                >
                                  {option}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                        <ErrorMessage
                          name="state"
                          component="div"
                          className="error"
                        />
                      </div>
                      {/* Country Dropdown */}
                      <div
                        className="form-field-input country-field state-field"
                        ref={countryRef}
                      >
                        <div
                          className="dropdown"
                          onClick={() => setIsCountryOpen(!isCountryOpen)}
                        >
                          <Field
                            type="text"
                            name="country"
                            placeholder="Country"
                            className="input-with-icons"
                            readOnly
                          />
                          {isCountryOpen ? (
                            <MdKeyboardArrowUp size={24} />
                          ) : (
                            <MdKeyboardArrowDown size={24} />
                          )}
                        </div>
                        {isCountryOpen && (
                          <div className="dropdown-options">
                            <div
                              onClick={() =>
                                handleOptionSelect(
                                  setFieldValue,
                                  "country",
                                  "India"
                                )
                              }
                              className={`dropdown-option ${
                                values.country === "India" ? "selected" : ""
                              }`}
                            >
                              India
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="two-input-box">
                      <div className="label-and-input-box">
                        <Field
                          type="number"
                          name="zip"
                          className="fix-width-input"
                          placeholder="Pincode"
                        />
                        <ErrorMessage
                          name="zip"
                          component="div"
                          className="error"
                        />
                      </div>
                      <div className="label-and-input-box">
                        {isFocused || user.phone ? (
                          <span className="default-india-code-checkout-add">
                            +91
                          </span>
                        ) : null}
                        <Field
                          type="text"
                          name="phone"
                          className="two-fix-width-input"
                          placeholder="Phone Number *"
                          style={{
                            paddingLeft:
                              isFocused || user.phone ? "51px" : "20px", // Adjust 12px to your normal padding
                          }}
                          // value={phone}
                          onFocus={() => setIsFocused(true)}
                          onBlur={() => setIsFocused(false)}
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>

                    <button type="submit" disabled={loading}>
                      {loading ? <Loader /> : "SAVE & CONTINUE"}
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
            <CheckoutOrderSummary order={cartItems} email={email} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CheckoutAddressCart;
