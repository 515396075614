import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import "../../assets/css/Login.scss";
import "../../assets/css/Signup.scss";
import { FaFacebook } from "react-icons/fa";
import { AiFillGoogleCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../utils";
import { useNavigate } from "react-router-dom";
import { Snackbar, Alert } from "@mui/material";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Loader from "../Common/Loader";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { gapi } from "gapi-script";
import { Helmet } from "react-helmet";
const Signup = () => {
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [valuePhone, setValuePhone] = useState("");
  const [loadingGoogle, setLoadingGoogle] = useState(false);
  const [loadingFacebook, setLoadingFacebook] = useState(false);
  useEffect(() => {
    window.scroll(0, 0);
    gapi.load("client:auth2", () => {
      gapi.auth2.init({
        clientId:
          "1001922032220-amo76ik848fbr2gbssnisvfhqhdhl0u7.apps.googleusercontent.com",
      });
    });
  }, []);
  const validationSchema = Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    phone: Yup.string().required("Phone Number is required"),
    dateOfBirth: Yup.string()
      .required("Date of Birth is required")
      .matches(
        /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4}$/,
        "Date of Birth must be in DD-MM-YYYY format"
      ),
    gender: Yup.string()
      .required("Gender is required")
      .test(
        "is-valid-gender",
        "Gender must be male, female, or other",
        (value) => {
          if (!value) return false; // Handle required validation
          const lowerCaseValue = value.toLowerCase();
          return ["male", "female", "other"].includes(lowerCaseValue);
        }
      ),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Must contain one lowercase, one uppercase, one number, and one special character"
      )
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const handleSubmit = async (values) => {
    // Convert dateOfBirth from DD-MM-YYYY to YYYY-MM-DD format for API submission
    const [day, month, year] = values.dateOfBirth.split("-");
    const formattedDateOfBirth = `${year}-${month}-${day}`;

    const dataToSend = {
      ...values,
      dateOfBirth: formattedDateOfBirth, // replace dateOfBirth with formatted date
      gender: values.gender.toLowerCase(), // Convert gender to lowercase
    };

    try {
      setLoading(true);
      const response = await axios.post(`${baseURL}/auth/register`, dataToSend);

      if (response.data.success === true) {
        setSnackbarMessage("Registration successful! Please log in.");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setTimeout(() => navigate("/login"), 2000); // Redirect after 2 seconds
      } else {
        setSnackbarMessage(response.data.message);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false); // Close Snackbar
  };
  const handleRedirect = (item) => {
    window.location.href = item;
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleGoogleLoginStart = () => {
    setLoadingGoogle(true);
  };
  const handleGoogleLoginSuccess = async (response) => {
    console.log("Google Login Success:", response);

    const userData = {
      type: "google",
      email: response.profileObj.email,
      firstName: response.profileObj.givenName,
      lastName: response.profileObj.familyName,
    };

    try {
      setLoading(true); // Show loader
      const response = await axios.post(`${baseURL}/auth/register`, userData);

      if (response.data.success === true) {
        // Handle successful login
        setSnackbarMessage("Registration successful! Please log in.");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setTimeout(() => navigate("/login"), 2000); // Redirect after 2 seconds
      } else {
        setSnackbarMessage(
          response.data.message ||
            "Something Went Wrong! Please try again later"
        );
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("Something Went Wrong! Please try again later");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoadingGoogle(false); // Stop loader
    }
  };
  const handleGoogleLoginFailure = (response) => {
    // console.log("errorgoogle", response);
    setSnackbarMessage("Something Went Wrong! Please try again later");
    setSnackbarSeverity("error");
    setSnackbarOpen(true);
    setLoadingGoogle(false);
  };

  const handleFacebookResponse = (response) => {
    console.log("Facebook Login Success:", response);

    // Send the Facebook response to your backend for further handling
    if (response.accessToken) {
      const userData = {
        accessToken: response.accessToken,
        userID: response.userID,
        email: response.email,
        name: response.name,
      };

      // Send userData to your backend for registration or authentication
      axios
        .post(`${baseURL}/auth/facebook-login`, userData)
        .then((res) => {
          console.log("Login Response:", res.data);
          if (res.data.success) {
            // Handle successful login
            setSnackbarMessage("Facebook login successful!");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
            setTimeout(() => navigate("/dashboard"), 2000); // Redirect after login
          } else {
            setSnackbarMessage(res.data.message);
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
          }
        })
        .catch((err) => {
          console.error("Facebook Login Error:", err);
        });
    }
  };
  return (
    <>
      <Helmet>
        <title>Sign Up | D'decor Rugs</title>
        <meta property="og:title" content="Sign Up | D'decor Rugs" />
      </Helmet>
      <Header />
      <div className="signup-page-container">
        <div className="header-of-login">
          <p>
            <span
              onClick={() => handleRedirect("/")}
              style={{ cursor: "pointer" }}
            >
              {" "}
              Home
            </span>{" "}
            - My account - Sign Up
          </p>
        </div>
        <div className="header-of-login-mobile-view">
          <p>
            <span
              onClick={() => handleRedirect("/")}
              style={{ cursor: "pointer" }}
            >
              {" "}
              Home
            </span>{" "}
            - My account - Sign Up
          </p>
        </div>
        <div className="signup-main-content">
          <div className="signup-main-form-content">
            <h4>SIGN UP</h4>
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                dateOfBirth: "",
                gender: "",
                password: "",
                confirmPassword: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values }) => (
                <Form className="signup-form-main-content">
                  <div className="signup-form">
                    <div className="form-field">
                      <Field
                        type="text"
                        name="firstName"
                        placeholder="First Name *"
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="form-field">
                      <Field
                        type="text"
                        name="lastName"
                        placeholder="Last Name *"
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>

                  <div className="signup-form">
                    <div className="form-field">
                      <Field
                        type="text"
                        name="email"
                        placeholder="Email Address *"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="form-field">
                      {isFocused || values.phone ? (
                        <span className="default-india-code-main-field">
                          +91
                        </span>
                      ) : null}
                      <Field
                        type="text"
                        name="phone"
                        placeholder="Phone Number *"
                        style={{
                          paddingLeft:
                            isFocused || values.phone ? "51px" : "20px", // Adjust 12px to your normal padding
                        }}
                        // value={valuePhone}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        // onChange={(e) => setValuePhone(e.target.value)}
                      />
                      <ErrorMessage
                        name="phone"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>

                  <div className="signup-form">
                    <div className="form-field">
                      <Field
                        type="text"
                        name="dateOfBirth"
                        placeholder="Date of Birth (DD-MM-YYYY) *"
                      />
                      <ErrorMessage
                        name="dateOfBirth"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="form-field">
                      <Field type="text" name="gender" placeholder="Gender *" />
                      <ErrorMessage
                        name="gender"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>

                  <div className="signup-form">
                    <div className="form-field">
                      <Field
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Password *"
                      />
                      <span
                        className="password-main-signup-icon"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="error"
                      />
                      <p className="password-text">
                        Must contain minimum one lowercase (a-z), uppercase
                        (A-Z), special characters (!@#$%&*) and number (0 - 9)
                      </p>
                    </div>
                    <div className="form-field">
                      <Field
                        type={showConfirmPassword ? "text" : "password"}
                        name="confirmPassword"
                        placeholder="Confirm Password *"
                      />
                      <span
                        className="confirmpassword-main-signup-icon"
                        onClick={toggleConfirmPasswordVisibility}
                      >
                        {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                      <ErrorMessage
                        name="confirmPassword"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>

                  <div className="buttons-text">
                    <p>
                      By proceeding I agree to the 
                      <Link to="/terms-and-conditions">
                        Terms & Conditions
                      </Link>{" "}
                      and <Link to="/privacy-policy">Privacy Policy</Link>
                    </p>{" "}
                    <button type="submit" disabled={loading}>
                      {loading ? <Loader /> : "SIGN UP"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>

            <p className="already-acc-text">
              Already have an account? <Link to="/login">Log in</Link>
            </p>

            <div className="google-facebook">
              <p>OR SIGNUP USING</p>
              <div className="facebook-google-button">
                <FacebookLogin
                  appId="3582269808738725" // Replace with your Facebook App ID
                  autoLoad={false}
                  fields="name,email,picture"
                  callback={handleFacebookResponse}
                  render={(renderProps) => (
                    <button
                      className="facebook-button"
                      onClick={renderProps.onClick}
                    >
                      <FaFacebook /> FACEBOOK
                    </button>
                  )}
                />
                <GoogleLogin
                  clientId="1001922032220-amo76ik848fbr2gbssnisvfhqhdhl0u7.apps.googleusercontent.com" // Replace with your client ID
                  onSuccess={handleGoogleLoginSuccess}
                  onFailure={handleGoogleLoginFailure}
                  autoLoad={false}
                  cookiePolicy={"single_host_origin"}
                  render={(renderProps) => (
                    <button
                      className="google-button"
                      onClick={() => {
                        handleGoogleLoginStart();
                        renderProps.onClick();
                      }}
                      disabled={loadingGoogle || renderProps.disabled}
                    >
                      <AiFillGoogleCircle /> GOOGLE
                    </button>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // Centering the Snackbar
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%", textAlign: "center" }} // Centering the text inside the Alert
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Signup;
